.ui.button.loading {
/* Important here will override semantic-ui's:
   * .ui.loading.loading.loading.loading.loading.loading.button selector
   */
  pointer-events: none !important;
}
.ui.button.text:not(.negative),
.ui.buttons.text .button:not(.negative) {
  font-weight: 700;
  color: var(--color-primary-slight-dark);
  background-color: transparent !important;
}
.ui.button.text:not(.negative) > i.icon,
.ui.buttons.text .button:not(.negative) > i.icon {
  opacity: 1;
  color: var(--color-primary-slight-dark);
}
.ui.button.text:hover:not(.negative):not(.loading),
.ui.buttons.text .button:hover:not(.negative):not(.loading),
.ui.button.text:focus:not(.negative):not(.loading),
.ui.buttons.text .button:focus:not(.negative):not(.loading) {
  color: var(--color-primary);
  background-color: var(--color-light-gray) !important;
}
.ui.button.text:hover:not(.negative):not(.loading) > i.icon,
.ui.buttons.text .button:hover:not(.negative):not(.loading) > i.icon,
.ui.button.text:focus:not(.negative):not(.loading) > i.icon,
.ui.buttons.text .button:focus:not(.negative):not(.loading) > i.icon {
  opacity: 1;
  color: var(--color-primary);
}
.ui.button.text:active:not(.negative):not(.loading),
.ui.buttons.text .button:active:not(.negative):not(.loading) {
  background-color: var(--color-light-gray-hover) !important;
  color: var(--color-primary);
}
.ui.button.text:active:not(.negative):not(.loading) > i.icon,
.ui.buttons.text .button:active:not(.negative):not(.loading) > i.icon {
  opacity: 1;
  color: var(--color-primary);
}
.ui.text.button.loading > i.icon {
  opacity: 0 !important;
}
.ui.text.button.loading:after {
  border-color: var(--loader-border-color);
  border-top-color: var(--loader-top-border-color);
}
.ui.button.text.gray-text {
  color: var(--color-text-gray-dark);
}
.ui.button.text.gray-text > i.icon {
  color: var(--color-text-gray-dark);
}
