@font-face {
  font-family: 'HankRnd';
  src: url(https://extension.videomatik.com.br/files/HankRnd-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'HankRnd';
  src: url(https://extension.videomatik.com.br/files/HankRnd-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HankRnd';
  src: url(https://extension.videomatik.com.br/files/HankRnd-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'HankRnd';
  src: url(https://extension.videomatik.com.br/files/HankRnd-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
:root {
  --color-primary: #ef432e;
  --color-primary-slight-dark: #d92611;
  --color-primary-dark: #aa1e0d;
  --color-secondary: #f8ca10;
  --color-info: #2f81a1;
  --color-info-light: #eef7fa;
  --color-success: #2fa13f;
  --color-success-slight-light: #78d885;
  --color-success-light: #eefaf0;
  --color-warning: #b69305;
  --color-warning-light: #fef9e5;
  --color-error: var(--color-primary-dark);
  --color-error-light: #fde5e2;
  --color-light-gray: #eaeaea;
  --color-light-gray-hover: #ddd;
  --color-light-gray-active: #d1d1d1;
  --color-message-text-gray: #676767;
  --color-layout-background: #f7f7f7;
  --color-text-gray: #bbb;
  --color-text-gray-dark: #909090;
  --color-new-green: var(--color-success-light);
  --button-secondary-hover-color: #fada5a;
  --button-secondary-active-color: #e8bb07;
  --navbar-sidemenu-mobile-bg-color: #7b1609;
  --loader-border-color: rgba(239,67,46,0.3);
  --loader-top-border-color: var(--color-primary);
  --default-button-loading-color: rgba(248,174,164,0.8);
  --login-page-background-color: var(--color-primary-dark);
  --color-text-black: #3c3c3c;
  --color-square-size: 32px;
  --color-square-margin: 3px;
  --box-border-radius: 15px;
  --box-border-radius-half: calc(var(--box-border-radius) / 2);
  --button-border-radius: 15px;
  --button-border-radius-half: calc(var(--button-border-radius) / 2);
  --border-color: var(--color-light-gray);
  --box-shadow: 0 2px 4px 0 rgba(34,36,38,0.12), 0 2px 10px 0 rgba(34,36,38,0.15);
  --form-input-border-color-focus: #f6988d;
  --form-input-border: 2px solid var(--border-color);
  --basic-button-border-hover: 2px solid var(--color-light-gray-hover);
  --basic-button-border-active: 2px solid var(--color-light-gray-active);
  --card-divider-border: 1px solid rgba(34,36,38,0.05);
  --color-square-border: 1px solid rgba(0,0,0,0.25);
  --color-square-border-hover: 1px solid rgba(0,0,0,0.15);
  --theme-font-family: HankRnd, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  --navbar-active-color: #c2220f;
  --page-footer-color: #e8e8e8;
}
.ui.fitted.toggle.checkbox {
  width: 66px;
}
.ui.toggle.checkbox {
  height: 28px;
}
.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  background: #fff;
  box-shadow: none;
  width: 22px;
  height: 22px;
  top: 3px;
}
.ui.toggle.checkbox input~.box:after,
.ui.toggle.checkbox input~label:after {
  left: 3px;
}
.ui.toggle.checkbox input:checked~.box:after,
.ui.toggle.checkbox input:checked~label:after {
  left: 41px;
}
.ui.toggle.checkbox input~.box:after,
.ui.toggle.checkbox input~label:after,
.ui.toggle.checkbox input:checked~.box:after,
.ui.toggle.checkbox input:checked~label:after {
  box-shadow: none;
}
.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  background: var(--color-light-gray-active);
  height: 28px;
  width: 66px;
}
.ui.toggle.checkbox .box:hover:before,
.ui.toggle.checkbox label:hover:before {
  background: var(--color-light-gray-hover);
}
.ui.checkbox input[type=checkbox]~.box:before,
.ui.checkbox:not(.toggle) input[type=checkbox]~label:before {
  border-radius: 5px;
}
.ui.checkbox input:checked:focus~.box:before,
.ui.checkbox input:checked:focus~label:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus~.box:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus~label:before,
.ui.checkbox input:focus~.box:before,
.ui.checkbox input:focus~label:before {
  border-color: var(--form-input-border-color-focus);
}
.ui.checkbox input:checked~label:after,
.ui.checkbox input:checked:focus~label:after {
  color: var(--color-text-black);
}
.ui.checkbox.radio input:checked~label:after,
.ui.checkbox.radio input:checked:focus~label:after {
  background-color: var(--color-text-black);
}
.ui.checkbox input~label {
  font-family: var(--theme-font-family);
}
.ui.button,
.ui.basic.button {
  border-radius: var(--button-border-radius);
  font-family: var(--theme-font-family);
}
.ui.button:not(.negative):not(.primary):not(.secondary):not(.text):not(.basic),
.ui.buttons .button:not(.negative):not(.primary):not(.secondary):not(.text):not(.basic) {
  color: var(--color-primary-slight-dark);
  background-color: var(--color-light-gray) !important;
}
.ui.button:not(.negative):not(.primary):not(.secondary):not(.text):not(.basic) > i.icon,
.ui.buttons .button:not(.negative):not(.primary):not(.secondary):not(.text):not(.basic) > i.icon {
  opacity: 1;
}
.ui.button:hover:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic),
.ui.buttons .button:hover:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic),
.ui.button:focus:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic),
.ui.buttons .button:focus:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic) {
  color: var(--color-primary);
  background-color: var(--color-light-gray-hover) !important;
}
.ui.button:hover:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic) > i.icon,
.ui.buttons .button:hover:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic) > i.icon,
.ui.button:focus:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic) > i.icon,
.ui.buttons .button:focus:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic) > i.icon {
  opacity: 1;
}
.ui.button:active:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic),
.ui.buttons .button:active:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic) {
  color: var(--color-primary);
  background-color: var(--color-light-gray-active) !important;
}
.ui.button:active:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic) > i.icon,
.ui.buttons .button:active:not(.negative):not(.loading):not(.primary):not(.secondary):not(.text):not(.primary):not(.secondary):not(.basic) > i.icon {
  opacity: 1;
}
.ui.button.loading:not(.primary):not(.secondary):not(.text):not(.secondary):not(.basic):after {
  border-color: var(--default-button-loading-color);
  border-top-color: var(--loader-top-border-color);
}
.ui.primary.button:not(.negative),
.ui.primary.buttons .button:not(.negative) {
  background: var(--color-primary-slight-dark);
}
.ui.primary.button:hover:not(.negative):not(.loading),
.ui.primary.buttons .button:hover:not(.negative):not(.loading),
.ui.primary.button:focus:not(.negative):not(.loading),
.ui.primary.buttons .button:focus:not(.negative):not(.loading) {
  background: var(--color-primary);
}
.ui.primary.button:active:not(.negative):not(.loading),
.ui.primary.buttons .button:active:not(.negative):not(.loading) {
  background: var(--color-primary-dark);
}
.ui.primary.button.loading:after {
  border-top-color: #fff;
}
.ui.secondary.button:not(.negative),
.ui.secondary.buttons .button:not(.negative) {
  background: var(--color-secondary);
  color: var(--color-text-black);
}
.ui.secondary.button:hover:not(.negative):not(.loading),
.ui.secondary.buttons .button:hover:not(.negative):not(.loading),
.ui.secondary.button:focus:not(.negative):not(.loading),
.ui.secondary.buttons .button:focus:not(.negative):not(.loading) {
  background: var(--button-secondary-hover-color);
}
.ui.secondary.button:active:not(.negative):not(.loading),
.ui.secondary.buttons .button:active:not(.negative):not(.loading) {
  background: var(--button-secondary-active-color);
}
.ui.secondary.button.loading:after {
  border-top-color: var(--color-text-black);
}
.ui.basic.tertiary.button:not(.negative),
.ui.basic.tertiary.buttons .button:not(.negative) {
  font-weight: 700;
  color: var(--color-primary-slight-dark) !important;
  box-shadow: 0 0 0 2px var(--color-primary-slight-dark) inset !important;
}
.ui.basic.tertiary.button:not(.negative) > i.icon,
.ui.basic.tertiary.buttons .button:not(.negative) > i.icon {
  opacity: 1;
  color: var(--color-primary-slight-dark) !important;
}
.ui.basic.tertiary.button:hover:not(.negative):not(.loading),
.ui.basic.tertiary.buttons .button:hover:not(.negative):not(.loading),
.ui.basic.tertiary.button:focus:not(.negative):not(.loading),
.ui.basic.tertiary.buttons .button:focus:not(.negative):not(.loading) {
  color: var(--color-primary) !important;
  box-shadow: 0 0 0 2px var(--color-primary) inset !important;
  background-color: var(--color-light-gray) !important;
}
.ui.basic.tertiary.button:hover:not(.negative):not(.loading) > i.icon,
.ui.basic.tertiary.buttons .button:hover:not(.negative):not(.loading) > i.icon,
.ui.basic.tertiary.button:focus:not(.negative):not(.loading) > i.icon,
.ui.basic.tertiary.buttons .button:focus:not(.negative):not(.loading) > i.icon {
  opacity: 1;
  color: var(--color-primary) !important;
}
.ui.basic.tertiary.button:active:not(.negative):not(.loading),
.ui.basic.tertiary.buttons .button:active:not(.negative):not(.loading) {
  color: var(--color-primary) !important;
  box-shadow: 0 0 0 2px var(--color-primary) inset !important;
  background-color: var(--color-light-gray-hover) !important;
}
.ui.basic.tertiary.button:active:not(.negative):not(.loading) > i.icon,
.ui.basic.tertiary.buttons .button:active:not(.negative):not(.loading) > i.icon {
  opacity: 1;
  color: var(--color-primary) !important;
}
.ui.tertiary.button.loading:not(.inverted):after {
  border-color: var(--loader-border-color);
  border-top-color: var(--loader-top-border-color);
}
.ui.basic.button:not(.tertiary),
.ui.basic.buttons:not(.tertiary) .button {
  box-shadow: none;
  border: var(--form-input-border);
}
.ui.basic.button:not(.tertiary):not(.loading):hover,
.ui.basic.buttons:not(.tertiary):not(.loading) .button:hover {
  border: var(--basic-button-border-hover);
}
.ui.basic.button:not(.tertiary):not(.loading):active,
.ui.basic.buttons:not(.tertiary):not(.loading) .button:active,
.ui.basic.button:not(.tertiary):not(.loading):focus,
.ui.basic.buttons:not(.tertiary):not(.loading) .button:focus {
  border: var(--basic-button-border-active);
}
.ui.card,
.ui.cards>.card {
  box-shadow: var(--box-shadow);
  border-radius: var(--box-border-radius);
}
.ui.card>:only-child,
.ui.cards>.card>:only-child {
  border-radius: var(--box-border-radius) !important;
}
.ui.card>.content>.header,
.ui.cards>.card>.content>.header,
.ui.card>.content,
.ui.cards>.card>.content {
  font-family: var(--theme-font-family);
  color: var(--color-text-black);
}
.ui.card>.content,
.ui.cards>.card>.content {
  border-top: var(--card-divider-border);
  padding: 1.3em;
}
.ui.card.less-padding>.content,
.ui.cards.less-padding>.card>.content {
  padding: 1em;
}
.ui.message {
  border-radius: var(--box-border-radius);
  border-width: 1px !important;
  border-style: solid;
  background-color: #fff;
  border-color: var(--color-text-gray-dark);
  color: var(--color-message-text-gray);
  box-shadow: none;
  font-family: var(--theme-font-family);
  font-size: 16px;
}
.ui.message >.content >.header:not(:last-child) {
  padding-bottom: 0.5em;
}
.ui.message >.content >.header {
  font-family: var(--theme-font-family);
}
.ui.success.message {
  background-color: var(--color-success-light);
  color: var(--color-success);
  border-color: var(--color-success);
  box-shadow: none;
}
.ui.error.message {
  background-color: var(--color-error-light);
  color: var(--color-error);
  border-color: var(--color-error);
  box-shadow: none;
}
.ui.info.message {
  background-color: var(--color-info-light);
  color: var(--color-info);
  border-color: var(--color-info);
  box-shadow: none;
}
.ui.warning.message {
  background-color: var(--color-warning-light);
  color: var(--color-warning);
  border-color: var(--color-warning);
  box-shadow: none;
}
.ui.form input:not([type]):focus,
.ui.form input[type=date]:focus,
.ui.form input[type=datetime-local]:focus,
.ui.form input[type=email]:focus,
.ui.form input[type=file]:focus,
.ui.form input[type=number]:focus,
.ui.form input[type=password]:focus,
.ui.form input[type=search]:focus,
.ui.form input[type=tel]:focus,
.ui.form input[type=text]:focus,
.ui.form input[type=time]:focus,
.ui.form input[type=url]:focus,
.ui.selection.dropdown:focus,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu,
.ui.selection.active.dropdown .menu,
.ui.form textarea:focus {
  border-color: var(--form-input-border-color-focus);
  border-radius: var(--button-border-radius);
}
.ui.selection.active.dropdown:hover .menu,
.ui.selection.active.dropdown .menu {
  width: calc(100% + 4px);
  margin-left: -2px;
  border: var(--form-input-border);
  border-color: var(--form-input-border-color-focus);
  box-shadow: var(--box-shadow);
}
.ui.upward.selection.dropdown .menu {
  border-top-width: 2px !important;
}
.ui.selection.dropdown .visible.menu.transition {
  border-radius: 0 0 var(--button-border-radius) var(--button-border-radius);
}
.ui.upward.selection.dropdown.visible {
  border-radius: 0 0 var(--button-border-radius) var(--button-border-radius) !important;
}
.ui.selection.dropdown.upward .visible.menu.transition {
  border-radius: var(--button-border-radius) var(--button-border-radius) 0 0;
}
.ui.selection.dropdown .menu>.item {
  border-top: none;
}
.ui.selection.dropdown,
.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url],
.ui.form textarea {
  border: var(--form-input-border);
  border-radius: var(--button-border-radius);
}
.ui.form .field {
  margin: 0 0 1.5em;
}
.ui.form .field > label {
  display: block;
  font-weight: 700;
  font-size: 1.2em;
  padding-bottom: 0.3em;
  font-family: var(--theme-font-family);
  color: var(--color-text-black);
}
.ui.form .field > label:not(:first-child) {
  padding-top: 1.5em;
}
.ui.modal {
  border-radius: var(--box-border-radius);
}
.ui.modal>:last-child {
  border-bottom-left-radius: var(--box-border-radius);
  border-bottom-right-radius: var(--box-border-radius);
}
.ui.modal>.icon:first-child+*,
.ui.modal>:first-child:not(.icon) {
  border-top-left-radius: var(--box-border-radius);
  border-top-right-radius: var(--box-border-radius);
}
.ui.modal>.header {
  border-bottom: var(--card-divider-border);
  color: var(--color-text-black);
}
.ui.modal>.actions {
  border-top: var(--card-divider-border);
}
@media only screen and (max-width: 767px) {
  .ui.modal > .header {
    padding: 1em !important;
  }
}
.ui.popup {
  border-radius: var(--button-border-radius);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--border-color);
}
.ui.top.popup:before {
  box-shadow: none;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
.ui.bottom.popup:before {
  box-shadow: none !important;
  top: -6px;
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}
.ui.top.center.popup:before,
.ui.top.left.popup:before {
  bottom: -6px;
  box-shadow: 1px 1px 1px 0 rgba(222,222,223,0.62);
}
.ui.secondary.menu .dropdown.item>.menu,
.ui.text.menu .dropdown.item>.menu {
  border-radius: var(--button-border-radius);
  box-shadow: var(--box-shadow);
}
.ui.vertical.menu .dropdown.item .menu {
  border-radius: var(--button-border-radius);
  box-shadow: var(--box-shadow);
}
.ui.pointing.upward.dropdown .menu,
.ui.top.pointing.upward.dropdown .menu {
  border-radius: var(--button-border-radius);
  box-shadow: var(--box-shadow);
}
.ui.pointing.upward.dropdown .menu,
.ui.top.pointing.upward.dropdown .menu > .item:first-child {
  border-radius: var(--button-border-radius) var(--button-border-radius) 0 0;
}
.ui.pointing.upward.dropdown .menu,
.ui.top.pointing.upward.dropdown .menu > .item:last-child {
  border-radius: 0 0 var(--button-border-radius) var(--button-border-radius);
}
.ui.pointing.upward.dropdown .menu:after,
.ui.top.pointing.upward.dropdown .menu:after {
  box-shadow: 1px 1px 0px 0 rgba(222,222,223,0.62);
}
.ui.dropdown {
  user-select: none;
}
.ui.menu .ui.dropdown .menu>.item,
.ui.dropdown .menu>.item {
  padding: 1em !important;
  font-size: 16px;
}
.ui.loader:before,
.ui.inverted.dimmer .ui.loader:before {
  border-color: var(--loader-border-color);
}
.ui.loader:after,
.ui.inverted.dimmer .ui.loader:after {
  border-top-color: var(--loader-top-border-color);
}
.ui.inverted.dimmer .ui.loader:before {
  width: 3em;
  height: 3em;
  border-width: 5px;
}
.ui.inverted.dimmer .ui.loader:after {
  width: 3em;
  height: 3em;
  border-width: 5px;
}
.ui.massive.loader:after,
.ui.massive.loader:before {
  border-width: 5px;
}
.ui.small.progress .bar {
  border-radius: var(--button-border-radius);
}
.ui.progress .bar {
  background-color: var(--color-text-gray-dark);
}
.ui.indicating.progress[data-percent^="1"] .bar,
.ui.indicating.progress[data-percent^="2"] .bar {
  background-color: var(--color-primary-slight-dark);
}
.ui.indicating.progress[data-percent^="3"] .bar,
.ui.indicating.progress[data-percent^="4"] .bar,
.ui.indicating.progress[data-percent^="5"] .bar {
  background-color: var(--color-secondary);
}
.ui.indicating.progress[data-percent^="6"] .bar,
.ui.indicating.progress[data-percent^="7"] .bar,
.ui.indicating.progress[data-percent^="8"] .bar {
  background-color: var(--color-success-slight-light);
}
.ui.indicating.progress[data-percent^="9"] .bar,
.ui.indicating.progress[data-percent^="100"] .bar {
  background-color: var(--color-success);
}
.ui.indicating.progress[data-percent="1"] .bar,
.ui.indicating.progress[data-percent="2"] .bar,
.ui.indicating.progress[data-percent="3"] .bar,
.ui.indicating.progress[data-percent="4"] .bar,
.ui.indicating.progress[data-percent="5"] .bar,
.ui.indicating.progress[data-percent="6"] .bar,
.ui.indicating.progress[data-percent="7"] .bar,
.ui.indicating.progress[data-percent="8"] .bar,
.ui.indicating.progress[data-percent="9"] .bar {
  background-color: var(--color-primary-slight-dark);
}
.ui.progress.success .bar {
  background-color: var(--color-success) !important;
}
.ui.progress.error .bar {
  background-color: var(--color-primary-slight-dark) !important;
}
html {
  font-size: 14px;
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 12px;
  }
}
body {
  color: var(--color-text-black);
}
h1,
h2,
h3,
h4,
h5 {
  font-family: var(--theme-font-family);
}
a {
  color: var(--color-primary);
}
a:hover {
  color: var(--color-primary-dark);
}
a.link {
  font-family: var(--theme-font-family);
}
.ui.segment {
  border-radius: var(--box-border-radius);
  border: none;
}
.ui.header {
  color: var(--color-text-black);
  font-family: var(--theme-font-family);
}
.ui.menu {
  font-family: var(--theme-font-family);
}
.ui.secondary.menu .item {
  border-radius: var(--button-border-radius);
}
.ui.active.visible.item.top.left.dropdown {
  border-radius: var(--button-border-radius);
}
html,
body {
  margin: 0px;
  background: #333;
  color: #fff;
}
