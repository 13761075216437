.app-container {
  padding: 8px;
}
.app-container .logocontainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.app-container .logocontainer .logo {
  height: 40px;
  width: 262.641px;
}
.app-container .content {
  margin-top: 20px;
}
.app-container .version {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 3px;
  background-color: #252525;
}
