.linter .greenCheck {
  color: #2fa13f;
}
.linter .redError {
  color: #ef432e !important;
}
.linter .yellowWarning {
  color: #f8ca10 !important;
}
.linter .extra-content {
  display: flex;
  margin-left: 13px;
  margin-top: 5px;
}
.linter .extra-content .button-container:not(:first-child) {
  margin-left: 1em;
  display: flex;
  align-items: center;
}
